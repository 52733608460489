import React from "react";
import studyingImg from "../srcassets/transparent_background.png"; // Adjust the path as necessary

const AboutusSection = () => {
  return (
    <div className="">
      <div className="container">
        <div className="mx-auto w-50 text-center">
          <h2 className="text_size_main">About Us</h2>
        </div>
      </div>
    </div>
  );
};

export default AboutusSection;
